<template>
  <VuePhoneNumberInput
    color="#fb6340"
    v-model="phoneNumberModel"
    size="lg"
    :required="required"
    :no-example="true"
    @update="onChangePhoneNumber"
    :fetch-country="true"
    :disabled="disabled"
    :translations="translations"
  />
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "phone-number-input",

  components: {
    VuePhoneNumberInput,
  },

  props: {
    phoneNumber: {
      type: String,
      default: null,
      description: "Phone number",
    },
    required: {
      type: Boolean,
      default: true,
      description: "Required phone number",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      phoneNumberModel: this.phoneNumber,
    };
  },

  computed: {
    translations: function () {
      return {
        countrySelectorLabel: this.$t("COMMON.COUNTRY_CODE"),
        countrySelectorError: this.$t("COMMON.SELECT_COUNTRY"),
        phoneNumberLabel: this.$t("COMMON.PHONE_NUMBER"),
        example: `${this.$t("COMMON.EXAMPLE")} :`,
      };
    },
  },

  setup() {},

  created() {},

  methods: {
    onChangePhoneNumber(input) {
      if (input.isValid) {
        const phoneParsed = parsePhoneNumber(
          input.phoneNumber,
          input.countryCode
        );
        this.$emit("phoneNumberChanged", phoneParsed.format("E.164"));
      } else {
        if (input.countryCallingCode && input.phoneNumber) {
          this.$emit(
            "phoneNumberChanged",
            `+${input.countryCallingCode}${input.phoneNumber}`
          );
        } else {
          this.$emit("phoneNumberChanged", ``);
        }
      }
    },
  },

  watch: {
    phoneNumber(phoneNumber) {
      this.phoneNumberModel = phoneNumber;
    },
  },
};
</script>
