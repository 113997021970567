<template>
  <el-select
    v-model="countryModel"
    :name="$t('COMMON.COUNTRIES')"
    :multiple="multiple"
    :filterable="filterable"
    @change="countryChanged"
    :disabled="disabled"
  >
    <el-option
      v-for="country in countries"
      :key="country.name"
      :value="country.name"
      :label="country.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { getAllCountriesName } from "i18n-iso-countries-regions";

export default {
  name: "country-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    country: {
      type: String,
      default: null,
      description: "Country id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      countryModel: this.country,
      // countries: getAllCountriesName(),
    };
  },

  computed: {
    countries: function () {
      return getAllCountriesName(this.$i18n.locale ?? "fr");
    },
  },

  setup() {},

  created() {},

  methods: {
    countryChanged(country) {
      this.$emit("countryChanged", country);
    },
  },

  watch: {
    country(country) {
      this.countryModel = country;
    },
  },
};
</script>
