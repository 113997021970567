<template>
  <div class="">
    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      :placeholder="$t('COMMON.ADDRESS')"
      :disabled="disabled"
    >
      <input
        class="form-control"
        id="address-input-unique"
        v-model="address"
        :placeholder="$t('COMMON.START_TYPING_CITY_REGION_COUNTRY')"
        :disabled="disabled"
      />
    </base-input>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";

export default {
  layout: "DashboardLayout",
  name: "addresses-selector",

  components: {},

  mixins: [formMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    addressValue: {
      type: [String, null],
      default: "",
    },
  },

  data() {
    return {
      address: this.addressValue,
      formattedAddress: "",
      components: {},
    };
  },

  created() {},

  methods: {
    onPlaceChanged(place) {
      let result = {};

      place?.address_components.forEach((item) => {
        item.types.forEach((type) => {
          result[type] = item.long_name;
        });
      });
      result = {
        ...result,
        address: place?.name,
        latitude: place?.geometry?.location?.lat() ?? undefined,
        longitude: place?.geometry?.location?.lng() ?? undefined,
      };

      if (result.street_number && result.route) {
        this.address = `${result.street_number} ${result.route}`;
      } else if (result.route) {
        this.address = `${result.route}`;
      } else if (result.postal_code) {
        this.address = `${result.postal_code}`;
      } else {
        this.address = `${result.postal_code}`;
      }
      this.getAddressData(result);
    },

    onFormChanged() {},

    getAddressData: function (addressData) {
      /*
       * country
       * state
       * city,
       * longitude
       * latitude
       * address
       */
      const addressValue = {
        city: addressData.locality,
      };
      addressValue.country = addressData.country;
      addressValue.state = addressData.administrative_area_level_1;
      addressValue.address = this.address;
      addressValue.longitude = addressData.longitude;
      addressValue.latitude = addressData.latitude;
      addressValue.zipcode = addressData.postal_code;
      this.$emit("addressInputChangeChanged", addressValue);
    },
  },
  mounted() {
    const geocoder = new google.maps.Geocoder();
    const input = document.getElementById("address-input-unique");
    const options = { types: ["address"], fields: ["address_components"] };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place) {
        this.onPlaceChanged(place);
      } else {
        console.warn("No place selected");
      }
    });

    geocoder.geocode({ address: this.address }, (results, status) => {
      if (status === "OK" && results[0]) {
        this.components = results[0].address_components.reduce(
          (obj, component) => {
            obj[component.long_name] = component.short_name;
            return obj;
          },
          {}
        );
      } else {
        console.warn("Geocoding failed", status);
      }
    });
  },
  watch: {},
};
</script>
