<template>
  <el-select
    v-model="stateModel"
    :name="$t('COMMON.STATES')"
    :multiple="multiple"
    :filterable="filterable"
    @change="stateChanged"
    :disabled="disabled"
  >
    <el-option
      v-for="state in states"
      :key="state.name"
      :value="state.name"
      :label="state.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { getRegionsByCountryName } from "i18n-iso-countries-regions";

export default {
  name: "state-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    country: {
      type: String,
      default: null,
      description: "Country id",
    },
    state: {
      type: String,
      default: null,
      description: "State id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stateModel: this.state ?? null,
    };
  },

  computed: {
    states: function () {
      return getRegionsByCountryName(this.$i18n.locale, this.country);
    },
  },

  setup() {},

  created() {},

  methods: {
    stateChanged(state) {
      this.$emit("stateChanged", state);
    },
  },

  watch: {
    state(state) {
      this.stateModel = state;
    },
  },
};
</script>
