<template>
  <div class="">
    <form
      class="add-form"
      ref="profile_form"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="() => {}"
    >
      <!-- Organization -->
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !hideOrganization
          "
        >
          <organization-selector
            :organization="contact.organization.id"
            :filterable="true"
            :showAll="false"
            @organizationChanged="
              (organizationId) => {
                contact.organization.id = organizationId;
                contact.contactable.id = null;
                onFormChanged();
              }
            "
            :disabled="!!contact.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.LOCATION')}`"
          :placeholder="$t('COMMON.LOCATION')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        >
          <locations-selector
            :locations="contact.allowedLocations"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :organization="contact.organization.id"
            @locationsChanged="
              (locations) => {
                contact.allowedLocations = locations;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.location" />
      </div>

      <!--
  <div class="form-wrapper full">
        <base-input
          v-if="!hideContactable"
          :label="`${$t('CONTACTS.CONTACT_TYPE')} (*)`"
          :placeholder="$t('CONTACTS.CONTACT_TYPE')"
        >
          <el-select
            :name="`${$t('CONTACTS.CONTACT_TYPE')}`"
            :placeholder="$t('CONTACTS.CONTACT_TYPE')"
            v-model="contact.contactable.type"
            @onchange="
              () => {
                onFormChanged();
              }
            "
          >
            <el-option value="customers" :label="$t(`COMMON.CUSTOMER`)" />
            <el-option value="suppliers" :label="$t(`COMMON.SUPPLIER`)" />
          </el-select>
        </base-input>
      </div>
  -->
      <div class="form-group-wrapper">
        <!-- Supplier -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.SUPPLIER')}`"
            :placeholder="$t('COMMON.SUPPLIER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
          >
            <supplier-selector
              :supplier="contact.supplier_id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="contact.organization.id"
              @supplierChanged="
                (supplierId) => {
                  contact.customer_id = null;
                  contact.supplier_id = supplierId;
                  contact.contactable.type = 'suppliers';
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.contactable" />
        </div>

        <!-- Customer -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.CUSTOMER')}`"
            :placeholder="$t('COMMON.CUSTOMER')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
          >
            <customer-selector
              :customer="contact.customer_id"
              :filterable="true"
              :showAll="false"
              :filterOrganization="contact.organization.id"
              @customerChanged="
                (customerId) => {
                  contact.customer_id = customerId;
                  contact.supplier_id = null;
                  contact.contactable.type = 'customers';
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.contactable" />
        </div>

        <!-- Title - FirstName - LastName-->
        <!-- Title -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.TITLE')} (*)`"
            :placeholder="$t('COMMON.TITLE')"
            v-model="contact.title"
          >
            <el-select
              :name="`${$t('COMMON.TITLE')}`"
              v-model.lazy="contact.title"
              :placeholder="$t('COMMON.TITLE')"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(label, val) in sourcesOption"
                :key="val"
                :value="val"
                :label="label"
              />
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.title" />
        </div>

        <!-- First name -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.FIRSTNAME')} (*)`"
            v-model="contact.firstname"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.firstname" />
        </div>

        <!-- Last name -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.LASTNAME')} (*)`"
            v-model="contact.lastname"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.lastname" />
        </div>

        <!-- email address -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.EMAIL')}`"
            :type="'email'"
            v-model="contact.email"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.email" />
        </div>

        <!-- Company name -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="`${$t('COMMON.COMPANY_NAME')}`"
            v-model="contact.company_name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.address" />
        </div>

        <!-- Phone number -->
        <div class="form-wrapper full">
          <div class="row equal">
            <div class="equal-item type">
              <base-input :label="`${$t('COMMON.TYPE')}`">
                <el-select
                  :label="$t('COMMON.TYPE')"
                  :placeholder="$t('COMMON.TYPE')"
                  v-model="contact.phone_type"
                  @change="
                    (type) => {
                      contact.phone_type = type;
                      onFormChanged();
                    }
                  "
                >
                  <el-option
                    v-for="(value, key) in phoneTypesOptions"
                    :key="key"
                    :value="value"
                    :label="$t(`COMMON.PHONE_TYPE_${value}`)"
                  />
                </el-select>
              </base-input>
            </div>
            <div class="equal-item phone">
              <base-input :label="`${$t('COMMON.PHONE')}`">
                <phone-number-input
                  :phoneNumber="contact.phone"
                  @phoneNumberChanged="
                    (phone) => {
                      contact.phone = phone;
                      onFormChanged();
                    }
                  "
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.phone" />
            </div>
            <div class="equal-item extension">
              <base-input
                :label="`${$t('COMMON.EXTENSION')}`"
                v-model="contact.phone_extension"
                @change="
                  () => {
                    onFormChanged();
                  }
                "
                :inputClasses="'extension-input'"
              />
              <validation-error :errors="apiValidationErrors.phone_extension" />
            </div>
          </div>
        </div>

        <!-- Other phone numbers -->
        <div class="form-wrapper full">
          <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
            <phone-numbers-selector
              :phoneNumbers="contact.other_phones"
              @phoneNumbersChanged="
                (phoneNumbers) => {
                  contact.other_phones = phoneNumbers;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.other_phones" />
        </div>

        <div class="form-wrapper full">
          <addresses-selector
            :addressValue="contact.address"
            @addressInputChangeChanged="
              (values) => {
                contact = {
                  ...contact,
                  ...values,
                };
                onFormChanged();
              }
            "
          />
        </div>

        <!-- Country - State - City - ZIP Code -->
        <div class="form-group-wrapper">
          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('COMMON.COUNTRY')}`"
              :placeholder="$t('COMMON.COUNTRY')"
            >
              <country-selector
                :country="contact.country"
                :filterable="true"
                :showAll="false"
                @countryChanged="
                  (country) => {
                    contact.country = country;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.country" />
          </div>

          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('COMMON.STATE')}`"
              :placeholder="$t('COMMON.STATE')"
            >
              <state-selector
                :country="contact.country"
                :state="contact.state"
                :filterable="true"
                :showAll="false"
                @stateChanged="
                  (state) => {
                    contact.state = state;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.state" />
          </div>

          <div class="form-group-wrapper-item">
            <base-input
              :label="`${$t('COMMON.CITY')}`"
              v-model="contact.city"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.city" />
          </div>

          <base-input
            class="form-group-wrapper-item"
            :label="`${$t('COMMON.ZIPCODE')}`"
            v-model="contact.zipcode"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.zipcode" />
        </div>
        <div class="form-group-wrapper-item full">
          <!-- Full Address -->
          <base-input
            class=""
            :label="`${$t('COMMON.ADDRESS')}`"
            v-model="contact.address"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.address" />
        </div>

        <div class="form-group-wrapper-item full">
          <!-- Birth day -->
          <base-input
            :label="`${$t('COMMON.BIRTHDAY')}`"
            :placeholder="$t('COMMON.BIRTHDAY')"
            v-model="contact.birthday"
            @change="onFormChanged"
          >
            <flat-picker
              :placeholder="$t('COMMON.BIRTHDAY')"
              :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                maxDate: 'today',
                locale: $flatPickrLocale(),
                disable: [
                  function (date) {
                    return false;
                  },
                ],
              }"
              class="form-control datepicker"
              v-model="contact.birthday"
              @on-change="onFormChanged()"
            >
            </flat-picker>
          </base-input>

          <validation-error :errors="apiValidationErrors.birthday" />
        </div>

        <!-- Tags -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="`${$t('COMMON.TAGS')}`"
            :placeholder="`${$t('COMMON.TAGS')}`"
          >
            <tags-selector
              :tags="contact.tags"
              @tagsChanged="
                (tags) => {
                  contact.tags = tags;
                  onFormChanged();
                }
              "
              :disabled="!contact.organization.id"
              :organization="contact.organization.id"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.tags" />
        </div>

        <!-- Excerpt -->
        <div class="form-group-wrapper-item full">
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="contact.excerpt" @change="onFormChanged()">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{
              contact.id
                ? $t("CONTACTS.EDIT_CONTACT")
                : $t("CONTACTS.ADD_CONTACT")
            }}
          </base-button>
        </div>
      </div>
    </form>

    <modal
      :show="showContactCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("CONTACTS.SAME_CONTACTS", {
                firstname: contact.firstname,
                lastname: contact.lastname,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="contacts"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.firstname} ${row.lastname}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('COMMON.EMAIL')"
                prop="email"
                min-width="220"
              />
              <el-table-column
                :label="$t('COMMON.PHONE')"
                prop="phone"
                min-width="180"
              />
              <el-table-column
                min-width="50"
                :fixed="$listActionsButtonsPosition()"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip
                    :content="$t('COMMON.VIEW')"
                    placement="top"
                    v-if="$currentUserCan($permissions.PERM_VIEW_CONTACTS)"
                  >
                    <a
                      type="text"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <router-link :to="$objectViewRoute(row)">
                        <i class="fal fa-expand-alt"></i>
                      </router-link>
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleContactCheckModalState"
          :disabled="loading"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select, Table, TableColumn } from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import { titleSourcesOption } from "@/constants/contacts";
import LocationsSelector from "@/components/LocationsSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    AddressesSelector,
    ValidationError,
    CountrySelector,
    TagsSelector,
    StateSelector,
    OrganizationSelector,
    CustomerSelector,
    SupplierSelector,
    PhoneNumberInput,
    HtmlEditor,
    flatPicker,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumbersSelector,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: [
    "contactData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideContactable",
    "hideSubmit",
  ],

  data() {
    let contactData = { ...this.contactData };
    contactData = this.$fillUserOrganizationData(contactData);
    return {
      contact: contactData,
      sourcesOption: titleSourcesOption,
      phoneTypesOptions,
      contacts: [],
      total: 0,
      showContactCheckModal: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      const checkUniqueContact = await this.checkContactUniqueInformation();
      if (checkUniqueContact) {
        await this.sendInformation();
      } else {
        this.showContactCheckModal = true;
      }
    },

    handleKeyDown(event) {
      // Empêche la soumission du formulaire lorsque la touche "Entrée" est pressée
      //event.preventDefault();
    },

    async sendInformation() {
      if (this.contact.contactable) {
        this.contact.contactable.id =
          this.contact?.customer_id ?? this.contact?.supplier_id ?? null;
      }
      if (!this?.contact?.contactable?.id) {
        delete this.contact.contactable;
      }
      let contactData = cloneDeep(this.contact);
      contactData = this.$fillUserOrganizationData(contactData);
      this.$emit("contactSubmitted", contactData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      try {
        //this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.contact?.firstname} ${this.contact?.lastname}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "organization,tags,allowedLocations",
        };

        if (this.contact?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.contact?.organization?.id,
            },
          };
        }

        if (this.contact?.firstname) {
          params = {
            ...params,
            // filter: { ...params.filter, firstname: this.contact?.firstname },
          };
        }

        if (this.contact?.lastname) {
          params = {
            ...params,
            // filter: { ...params.filter, lastname: this.contact?.lastname },
          };
        }

        await this.$store.dispatch("contacts/list", params);
        this.contacts = this.$store.getters["contacts/list"];
        this.total = this.$store.getters["contacts/listTotal"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkContactUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.contact?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      await this.toggleContactCheckModalState();
      await this.sendInformation();
    },

    async toggleContactCheckModalState() {
      this.showContactCheckModal = !this.showContactCheckModal;
    },

    getAddressData: function (addressData) {
      //console.log(addressData);
      /*
       *country
       * state
       * city,
       * longitude
       * latitude
       * address
       */
      this.contact.city = addressData.locality;
      this.contact.country = addressData.country;
      this.contact.state = addressData.administrative_area_level_1;
      this.contact.address = addressData.route;
      this.contact.longitude = addressData.longitude;
      this.contact.latitude = addressData.latitude;
      this.contact.zipcode = addressData.postal_code ?? this.contact.zipcode;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    contactData(contactData) {
      if (contactData) {
        this.contact = {
          ...this.contact,
          ...cloneDeep(contactData),
        };
      }
    },
  },
};
</script>
